import React from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const MenuPage = ({ children, data }) => (
  <div className="menu-wrapper">
    <div className="container">
      <div className="Content">
        {children}
        <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 768: 2 }}>
          <Masonry className="menu">
            {data.allInternalResData.nodes.map(menus => (
              <div className="Menus" key={menus.id}>
                <div className="name">
                  <h1>{menus.name}</h1>
                  {menus.description && <p>{menus.description}</p>}
                </div>
                <div className="items-groups">
                  {menus.items.map(item => (
                    <div className="items" key={item.id}>
                      <div className="main">
                        <span className="item-name">{item.name}</span>
                        {item.price === 0 ? null : (
                          <span className="item-price">{`$${item.price}`}</span>
                        )}
                        {item.description && (
                          <p className="item-des">{item.description}</p>
                        )}
                      </div>
                      {item.sizes.length === 0 ? null : (
                        <ul className="item-ul">
                          <h6>Sizes:</h6>
                          {item.sizes.map(size => (
                            <li key={size.menu_item_id}>
                              {size.name}{" "}
                              {size.price === 0 ? null : (
                                <span>- ${size.price.toFixed(2)}</span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                  {menus.groups.length !== 0 && (
                    <div className="options">
                      {menus.groups.map(group => (
                        <ul key={group.menu_id} className="item-ul">
                          <span>
                            <h6>{group.name}</h6>
                          </span>
                          {group.options.map(option => (
                            <li key={option.id}>
                              {option.name}
                              {option.price === 0 ? null : (
                                <> - ${option.price.toFixed(2)}</>
                              )}
                            </li>
                          ))}
                        </ul>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  </div>
)

export default MenuPage
